import React, { useContext, useEffect, useState } from "react";
import { EventMeeting } from "../../../../models/EventMeeting";
import { List } from "immutable";
import Screen from "./Screen";
import { useDispatch, useSelector } from "react-redux";
import { selectServiceUsers } from "../../../../assets/services";
import {
  fetchGetMeeting,
  MeetingsStatus,
  selectMeetings,
} from "../../../../assets/meetings";
import { fetchEventsWithServiceDayApi } from "../../../../assets/events";
import { EnumSearchOption } from "../../../../pages/Dashboard/components/MeetingContainer";
import moment from "moment";
import 'moment/locale/ca';
import { statusMeeting } from "../../../../util/meeting";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { CONTENT_MEETING_TYPE } from "./components/BOTableMeeting/type/contentMeeting";
interface Props {
  meetings: EventMeeting[];
  contentMeeting?: (string | any)[];
  servicesRefresh?: () => void;
  onClickMeeting?: (x: any) => void;
  disabledMeeting?: (x: any) => boolean;
  contentFilter?: string[];
}

const BOTableMeeting = (props: Props) => {
  const [listMeetings, setListMeetings] = useState<EventMeeting[]>([]);
  const meetingsQueue = useSelector(selectMeetings);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    typeFilter: EnumSearchOption.CodeMeeting,
    valueFilter: "",
  });
  const language = i18next.language;
  moment.locale(language);
  const { t } = useTranslation();
  useEffect(() => {
    const v: EventMeeting[] = [];
    props.meetings.forEach((m) => {
      const meetingStatus: any = meetingsQueue.find(
        (mq: MeetingsStatus) => m.code === mq.meetingID
      );
      const lastHistory = m.changeHistory ? m.changeHistory[m.changeHistory.length - 1] : undefined;
      if (meetingStatus) {
        m = {
          ...m,
          assignedTo: m.assignedTo || meetingStatus.assignedTo,
          url: m.url || meetingStatus.meetingURL,
        };
      }
      else if (lastHistory && lastHistory.changeType === "AssignMeetingEarly") {
        const assignedTo = lastHistory.changeInfo;
        m = { ...m, assignedTo }
      }
      else if (moment(m.start).format("YYYYMMDD") > moment().format("YYYYMMDD")) {
        m = { ...m, assignedTo: "NotAssigned" }
      } else {
        m = { ...m, assignedTo: m.assignedTo || "" };
      }
      m = {
        ...m,
        day: moment(m.start).format("YYYYMMDD"),
      };
      v.push(m);
    });

    const aux = v.sort((a, b) => {
      const startA: any = a.start;
      const startB: any = b.start;
      if (!startA) return -1;
      else if (!startB) return 1;
      else if (startA.getTime && startB.getTime) {
        return startA.getTime() - startB.getTime();
      } else {
        return startA.localeCompare(startB);
      }
    });
    setListMeetings(aux);
  }, [meetingsQueue, props.meetings]);

  const contentMeetingList = props.contentMeeting || [
    CONTENT_MEETING_TYPE.START,
    CONTENT_MEETING_TYPE.CODE,
    CONTENT_MEETING_TYPE.STATUS,
    CONTENT_MEETING_TYPE.ACTION,
  ];
  const servicesRefresh = async () => {
    setIsLoading(true);
    props.servicesRefresh && props.servicesRefresh();
    await setTimeout(async () => {
      setIsLoading(false);
    }, 1000);
  };
  const onHandleChangeFilterMeeting = (form: any) => {
    setFilter(form);
  };

  const formatMeetting = (meeting: any) => {
    const newMeeting = { ...meeting };
    newMeeting["status"] = t(statusMeeting(meeting["status"]));
    if (contentMeetingList.includes("code")) {
      newMeeting["code"] = newMeeting["code"] || meeting["meetingId"];
    }
    if (contentMeetingList.includes("start")) {
      const start = meeting["start"] || meeting["meetingStart"];
      newMeeting["full-start"] = start;
      newMeeting["start"] = moment(start.toString()).format("HH:mm");
    }
    if (contentMeetingList.includes("full-start")) {
      newMeeting["full-start"] = moment(meeting["start"].toString()).format(
        "DD MMM HH:mm");
        console.log("", newMeeting["full-start"]);

    }
    return newMeeting;
  };
  const formatedMeettings = listMeetings.map((meeting) =>
    formatMeetting(meeting)
  );
  const byFilter = (meeting: any) => {
    try {
      const filterValue = filter.valueFilter.toUpperCase();
      if (filterValue === "") return true;
      else if (filter.typeFilter == EnumSearchOption.ALL) {
        const valuesFiltered = Object.entries(meeting)
          .filter(([key, value]) => contentMeetingList.includes(key))
          .map(([key, value]) => value);
        return valuesFiltered.some(
          (_: any) =>
            typeof _ == typeof "" && _.toUpperCase().includes(filterValue)
        );
      } else {
        const meetingValue = meeting[filter.typeFilter].toUpperCase();
        return meetingValue.includes(filterValue);
      }
    } catch (ex) {
      console.error("ex", ex);
    }
  };

  const meetings: any = formatedMeettings.filter(byFilter);
  return (
    <>
    <Screen
      meetings={meetings}
      contentMeeting={contentMeetingList}
      servicesRefresh={props.servicesRefresh && servicesRefresh}
      isLoading={isLoading}
      onHandleChangeFilterMeeting={onHandleChangeFilterMeeting}
      onClickMeeting={props.onClickMeeting}
      disabledMeeting={props.disabledMeeting}
    />
    </>
  );
};

export default BOTableMeeting;
