import { getUserInfo } from './users';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../app/store";
import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";
import { AreaInfo } from "../models/AreaInfo";
import { Service } from "../models/Service";
import { ServiceInfo } from "../models/ServiceInfo";
import { mapAsyncFlatten } from "../util/array";
import { fetchMSGraph } from "../util/auth";
import { getAreaInfo, getServiceInfo } from "./api";
import { getServicesCodes, getServicesUser } from "./areas";

const URL_MEETING = `${process.env.REACT_APP_MEETING_SERVICES}`;

export type MeetingsCount = {
  code: string;
  count: number;
};

export type ResponseAssignMeeting = {
  response: boolean | null;
};

export type MeetingsStatus = {
  areaCode: string;
  visibleFromTimeUTC: string;
  isVisibleToServants: boolean;
  assignedTo: string;
  meetingID: string;
  typeOfMeeting: string;
  meetingURL: string;
  meetingStart: string;
  meetingStartUTC: string;
};

const defaultMeetingStatus: MeetingsStatus = {
  areaCode: "",
  visibleFromTimeUTC: "",
  isVisibleToServants: false,
  assignedTo: "",
  meetingID: "",
  typeOfMeeting: "",
  meetingURL: "",
  meetingStart: "",
  meetingStartUTC: "",
};

interface MeetingsState {
  meetings: MeetingsStatus[];
  meetingsCount: MeetingsCount[];
  responsePostMeetingsAddUser: ResponseAssignMeeting;
}

const initialState: MeetingsState = {
  meetings: [],
  meetingsCount: [],
  responsePostMeetingsAddUser: { response: null },
};

export const meetingsSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {
    GET_MEETING: (state, action: PayloadAction<MeetingsStatus[]>) => {
      state.meetings = action.payload;
    },
    GET_MEETINGS_COUNT: (state, action: PayloadAction<any>) => {
      state.meetingsCount = action.payload;
    },
    POST_MEETINGS_ADD_USER: (
      state,
      action: PayloadAction<ResponseAssignMeeting>
    ) => {
      state.responsePostMeetingsAddUser = action.payload;
    },
  },
});

export const { GET_MEETING, GET_MEETINGS_COUNT, POST_MEETINGS_ADD_USER } =
  meetingsSlice.actions;


const getMeetings = async (areaCode: string, serviceCode: string, token: string) => {
  const url = `${URL_MEETING}/GetMeetingsQueueStatus/${areaCode}/${serviceCode}/`;
  const arrayMeetings: MeetingsStatus[] = [];
  try {
    const response = await fetchMSGraph(url, token);
    if (response.StatusCode !== 204) {
      const meetingsQueueStatusResponse = await response.json();
      const data: MeetingsStatus[] = meetingsQueueStatusResponse;
      data.forEach((meeting: MeetingsStatus) => {
        arrayMeetings.push({
          areaCode: meeting.areaCode,
          visibleFromTimeUTC: meeting.visibleFromTimeUTC,
          isVisibleToServants: meeting.isVisibleToServants,
          assignedTo: meeting.assignedTo,
          meetingID: meeting.meetingID,
          typeOfMeeting: meeting.typeOfMeeting,
          meetingURL: meeting.meetingURL,
          meetingStart: meeting.meetingStart,
          meetingStartUTC: meeting.meetingStartUTC,
        });
      });

      return arrayMeetings
    }
  }

   catch (error) {
    console.warn('Error API "Meetings" | Function getMeetings: ' + error);
  }
  return []
}

const getMeetingNoArea = async (service: string, token: string) => {
  const area = await getServiceInfo(service, token)
    .then((_): ServiceInfo => _.json())
    .then(_ => _.areaCode);
  return getMeetings(area, service, token)

}


const getMeetingNoServices = async (areaCode: string | string[] | undefined, token: string, userCode?: string,) => {
  try {
    if (!areaCode) return [];
    const arrayAreas: any[] = Array.isArray(areaCode) ? areaCode : [areaCode];
    let serviceAssign: string[];
    if (userCode) {
      serviceAssign = await getServicesUser(userCode, token).then(services => services.map(({ code }: any) => code))
    }
    return await mapAsyncFlatten(arrayAreas, async (area) => {
      const response = await getAreaInfo(area, token);
      if (response.ok && response.status !== 204) {
        const data: AreaInfo = await response.json();
        const services = data.services.map(item => item.code).filter(item => !serviceAssign || serviceAssign.includes(item))
        return mapAsyncFlatten(services, async (service) => {
          const meetings = await getMeetings(area, service, token)
          return meetings;
        })
      }
      return []
    })
  } catch (error) {
    console.warn('Error API "Meetings" | Function getMeetingNoServices: ' + error);
  }
  return []
}

const getMeetingArrayServices = async (areaCode: string | string[] | undefined, arrayServices: string[], token: string) => {
  return mapAsyncFlatten(arrayServices, async (service) => {
    const isAreaSelected = areaCode && !Array.isArray(areaCode)
    let area: any = areaCode;
    if (!isAreaSelected) {
      return getMeetingNoArea(service, token);
    } else {
      return getMeetings(area, service, token)
    }
  })
}

const getMeetingsNoServicesNoArea = async (areaCode: string | string[] | undefined, token: string) => {
  let arrayServices: any;
  try {
    const { code: codeUser } = await getUserInfo(token).then(info => info.json());
    arrayServices = await getServicesUser(codeUser, token).then(({ code }) => code);
  } catch (error) {
    arrayServices = await getServicesCodes(token);
  }
  return getMeetingArrayServices(areaCode, arrayServices, token);
}

export const fetchGetMeeting = (areaCode: string | string[] | undefined, serviceCode: string | string[] | undefined, token: string): AppThunk =>
  async (dispatch) => {
    let arrayMeetings = []
    let userCode;
    try {
      const { code } = await getUserInfo(token).then(info => info.json());
      userCode = code;
    } catch (error) { }
    if (!serviceCode || !serviceCode.length) {      
      arrayMeetings = await getMeetingNoServices(areaCode, token, userCode);
    } else {
      const arrayServices: any[] = Array.isArray(serviceCode) ? serviceCode : [serviceCode];
      arrayMeetings = await getMeetingArrayServices(areaCode, arrayServices, token);
    }

    // await dispatch(fetchEventsWithServiceDay(arrayMeetings));
    await dispatch(GET_MEETING(arrayMeetings));
  };

export const countMeetings =
  (areaCode: string, arrayService: Service[], token: string): AppThunk =>
    async (dispatch) => {
      const url = `${URL_MEETING}/GetMeetingsQueueStatus/${areaCode}/`;
      let count: MeetingsCount[] = [];
      try {
        let response;
        await Promise.all(
          arrayService.map(async (service: Service, index) => {
            response = await fetchMSGraph(url + service.code, token);
            if (response.ok) {
              const meetingsQueueStatusResponse = await response.json();
              count.push({ code: service.code, count: meetingsQueueStatusResponse instanceof Object ? meetingsQueueStatusResponse.length : 0 });
            }
          })
        );
      } catch (error) {
        console.warn('Error API "Meetings" | Function countMeetings: ' + error);
      }
      await dispatch(GET_MEETINGS_COUNT(count));
    };

export const postAssignMeeting =
  (
    areaCode: string,
    meetingCode: string,
    userCode: string,
    express: boolean,
    token: string
  ): AppThunk =>
    async (dispatch) => {
      let responseDispatch: ResponseAssignMeeting = { response: null };
      const url = `${URL_MEETING}/AssignMeeting`;

      const body = JSON.stringify({
        meetingArea: areaCode,
        meetingID: meetingCode,
        servantIdToAssignMeeting: userCode,
        isExpressMeeting: express
      });

      try {
        const response: Response = await fetchMSGraph(
          url,
          token,
          EnumHTTPMethod.POST,
          body
        );
        if (response.ok) {
          responseDispatch.response = true;
        } else {
          responseDispatch.response = false;
        }
      } catch (error) {
        console.warn(
          'Error API "Meetings" | Function postAssignMeeting: ' + error
        );
        responseDispatch.response = false;
      }
      await dispatch(POST_MEETINGS_ADD_USER(responseDispatch));
    };

export const cleanAssignMeeting = (): AppThunk => async (dispatch) => {
  let responseDispatch: ResponseAssignMeeting = { response: null };
  await dispatch(POST_MEETINGS_ADD_USER(responseDispatch));
};

export const selectMeetings = (state: RootState) => state.meetings.meetings;
export const selectMeetingsCount = (state: RootState) =>
  state.meetings.meetingsCount;
export const selectMeetingsAssignUser = (state: RootState) =>
  state.meetings.responsePostMeetingsAddUser;

export default meetingsSlice.reducer;
