import React, { FC, Fragment, ReactElement, useEffect, useState } from "react";
import {
  Button,
  Dimmer,
  Dropdown,
  Input,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react";
import BORowMeeting from "./components/BOTableMeeting/BORowMeeting";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { EventMeeting } from "../../../../models/EventMeeting";
import FilterMeeting from "./components/FilterMeeting/FilterMeeting";

interface BOTableMeetingProps {
  meetings: EventMeeting[];
  contentMeeting: (string | any)[];
  servicesRefresh?: () => void;
  isLoading: boolean;
  onHandleChangeFilterMeeting: any;
  onClickMeeting?: (x: any) => void;
  disabledMeeting?: (x: any) => boolean;
}

const BOTableMeeting: FC<BOTableMeetingProps> = (
  props: BOTableMeetingProps
): ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        {props.servicesRefresh && (
          <Button
            negative
            content={t("refresh")}
            icon={"refresh"}
            onClick={props.servicesRefresh}
          />
        )}
        <FilterMeeting onHandleChange={props.onHandleChangeFilterMeeting} />
      </div>
      {props.isLoading ? (
        <Segment padded={"very"} size={"massive"}>
          <Dimmer active inverted>
            <Loader size="medium">{t("loading.defect")}</Loader>
          </Dimmer>
        </Segment>
      ) : (
        <Table id={"table-service-meeting"} key={"table-user"}>
          <Table.Header>
            <Table.Row>
              {props.contentMeeting.map((_ , index) => (
                <Table.HeaderCell key = {index}>
                  {t(`detail.meeting.title.${_.type || _}`)}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.meetings.length > 0 ? (
              <BORowMeeting
                meetings={props.meetings}
                servicesRefresh={props.servicesRefresh}
                contentMeeting={props.contentMeeting}
                onClickMeeting={props.onClickMeeting}
                disabledMeeting={props.disabledMeeting}
              />
            ) : (
              <Table.Row>
                <Table.Cell colSpan={"4"}>
                  {t("detail.meeting.no_meeting_found")}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
export default BOTableMeeting;
