import { RouteComponentProps } from "react-router-dom";
import { AuthContextType } from "./auth";

export type Service = {
  key: string;
  text: string;
  value: string;
};

export type Meeting = {
  meetingID: string;
  typeOfMeeting: string;
  meetingURL: string;
  meetingStart: string;
};

export type MeetingListType = {
  meetingStart: string;
  meetingId: string;
  userId: string;
};

export type Queue = {
  visibleFromTimeUTC: string;
  isVisibleToServants: boolean;
  assignedTo: string;
  meetingID: string;
  typeOfMeeting: string;
  meetingURL: string;
  meetingStart: string;
};

export interface QueuedMeeting {
  meetingID: string;
  typeOfMeeting: string;
  meetingURL: string;
  meetingStart: Date;
  meetingStartUTC: Date;
}

export type Attendee = {
  fullName: string;
  culture: string;
  email: string;
  phone: string;
  type: string;
  isOrganizer: boolean;
  personId: string;
  observations?: string;
  comment?: string;
};

export interface AttendeeExpress {
  isOrganizer: boolean;
  type: string;
  name: string;
  surname: string;
  secondSurname: string;
  email: string;
  phone: string;
  id: string;
  culture: string;
  comment: string;
  observations?: string;
  isSendOTP?: boolean,
}

export interface ExpressMeeting {
  start: string;
  durations: number;
  serviceCode: string;
  comment?: string;
  zipCode: string;
  attendees: AttendeeExpress[];
  isOtpAvailable: boolean;
  isProgrammed?: boolean;
}
export interface AddUser {
  culture: string;
  fullName: string;
  email: string;
  phone: string;
}

export const defaultQueue: Queue = {
  assignedTo: "",
  isVisibleToServants: false,
  meetingID: "",
  meetingStart: "",
  meetingURL: "",
  typeOfMeeting: "",
  visibleFromTimeUTC: "",
};

export const defaultAttendee: Attendee = {
  culture: "",
  email: "",
  fullName: "",
  isOrganizer: false,
  personId: "",
  phone: "",
  type: "",
  comment: "",
};

export const defaultQueueMeeting: QueuedMeeting = {
  meetingID: "",
  meetingStart: new Date(),
  meetingStartUTC: new Date(),
  meetingURL: "",
  typeOfMeeting: "",
};

export const defaultList: MeetingListType = {
  meetingId: "",
  meetingStart: "",
  userId: "",
};
