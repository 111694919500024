import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState, store } from "../app/store";
import { User } from "../models/User";
import { defaultServiceInfo, ServiceInfo } from "../models/ServiceInfo";
import { Service } from "../models/Service";
import { fetchMSGraph, msalInstance } from "../util/auth";
import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";
import { EnumAPI } from "../enum/EnumAPI";
import { race } from "rxjs";
import { boolean } from "yup";

const URL_AREA = `${process.env.REACT_APP_API_URL}/${EnumAPI.Services}`;

export type ResponseServicesUserDelete = {
  response: boolean | null;
};

export type ResponseServicesUserPost = {
  response: boolean | null;
};

export type UserWithServices = {
  mail: string;
  name: string;
  active: boolean;
  services: Service[];
};
interface servicesState {
  users: User[];
  serviceInfo: ServiceInfo;
  userServices: UserWithServices[];
  servicesUserDelete: ResponseServicesUserDelete;
  servicesUserPost: ResponseServicesUserPost;
}

const initialState: servicesState = {
  users: [],
  serviceInfo: defaultServiceInfo,
  userServices: [],
  servicesUserDelete: { response: null },
  servicesUserPost: { response: null },
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    GET_SERVICE_DATA: (state, action: PayloadAction<ServiceInfo>) => {
      state.serviceInfo = action.payload;
    },
    GET_SERVICE_USER: (state, action: PayloadAction<User[]>) => {
      action.payload.map(user => {
        state.users.push(user);
      });
    },
    GET_SERVICE_USER_ACTION: (state, action: PayloadAction<User[]>) => {
        state.users = action.payload;
    },
    GET_SERVICE_BY_USER: (state, action: PayloadAction<UserWithServices[]>) => {
      state.userServices = action.payload;
    },
    POST_SERVICE_USER: (
      state,
      action: PayloadAction<ResponseServicesUserPost>
    ) => {
      state.servicesUserPost = action.payload;
    },
    DELETE_SERVICE_USER: (
      state,
      action: PayloadAction<ResponseServicesUserDelete>
    ) => {
      state.servicesUserDelete = action.payload;
    },
    CLEAN_SERVICES: (state, action: PayloadAction<servicesState>) => {
      state.serviceInfo = action.payload.serviceInfo;
      state.servicesUserDelete = action.payload.servicesUserDelete;
      state.servicesUserPost = action.payload.servicesUserPost;
      state.userServices = action.payload.userServices;
      state.users = action.payload.users;
    },
  },
});

export const {
  GET_SERVICE_DATA,
  GET_SERVICE_USER,
  GET_SERVICE_USER_ACTION,
  GET_SERVICE_BY_USER,
  POST_SERVICE_USER,
  DELETE_SERVICE_USER,
  CLEAN_SERVICES,
} = servicesSlice.actions;

export const fetchServiceInfo =
  (codeService: string, token: string): AppThunk =>
  async (dispatch) => {
    let data: ServiceInfo = defaultServiceInfo;
    const url = `${URL_AREA}/${codeService.toLocaleUpperCase()}`;

    try {
      const response: Response = await fetchMSGraph(url, token);
      if (response.ok) {
        data = (await response.json()) as ServiceInfo;
      }
    } catch (error) {
      console.warn("Error Function fetchService: " + error);
    }
    await dispatch(GET_SERVICE_DATA(data));
  };

export const getServicesByUserApi =
  (code: string, token: string): AppThunk =>
  async (dispatch) => {
    let arrayUser: User[] = [];  
    const url = `${URL_AREA}/${code.toLocaleUpperCase()}/users`;
    try {
      const data: Response = await fetchMSGraph(url, token);
      if (data.ok) {
        const userData: User[] = await data.json();
        userData.forEach((user: User) => {
          var trobat : boolean = false;
          for (let i = 0; i < arrayUser.length; i++) {
            if (arrayUser[i].code === user.code) trobat = true;
          }
          if (!trobat) arrayUser.push(user);
        });
      }
    } catch (error) {
      console.warn(
        'Error API "Services" | Function "getServicesByUserApi": ' + error
      );
    }
    await dispatch(GET_SERVICE_USER_ACTION(arrayUser));
  };

export const fetchPostServiceUser =
  (serviceCode: string, user: User, token: string): AppThunk =>
  async (dispatch) => {
    const responseDispatch: ResponseServicesUserPost = { response: null };
    const url = `${URL_AREA}/${serviceCode}/users?code=${serviceCode}&userCode=${user.code}`;
    try {
      const response: Response = await fetchMSGraph(
        url,
        token,
        EnumHTTPMethod.PUT
      );
      if (response.ok) {
        const data = await response.json();
        responseDispatch.response = data;
      } else {
        const data = await response.json();
        responseDispatch.response = false;
        console.warn(
          'Error "SERVICES" - Function "fetchPostServiceUser" : ',
          data
        );
      }
    } catch (error) {
      responseDispatch.response = false;
      console.warn(
        'Error "SERVICES" - Function "fetchDeleteServiceUser" : ',
        error
      );
    }
    await dispatch(POST_SERVICE_USER(responseDispatch));
  };

export const fetchDeleteServiceUser =
  (serviceCode: string, userCode: string, token: string): AppThunk =>
  async (dispatch) => {
    const responseDispatch: ResponseServicesUserDelete = { response: null };
    const url = `${URL_AREA}/${serviceCode}/users/${userCode}`;
    try {
      const response: Response = await fetchMSGraph(
        url,
        token,
        EnumHTTPMethod.DELETE
      );
      if (response.ok) {
        const data = await response.json();
        responseDispatch.response = data;
      } else {
        const data = await response.json();
        console.warn(
          'Error "SERVICES" - Function "fetchDeleteServiceUser" : ',
          data
        );
        responseDispatch.response = false;
      }
    } catch (error) {
      console.warn(
        'Error "SERVICES" - Function "fetchDeleteServiceUser" : ',
        error
      );
      responseDispatch.response = false;
    }
    await dispatch(DELETE_SERVICE_USER(responseDispatch));
  };

  export const getUsersByService = async (serviceCode: string, token: string) => { 

   // console.log("Accediendo al método")

    const url = `${URL_AREA}/${serviceCode.toLocaleUpperCase()}/users`;
    try {
      const data: Response = await fetchMSGraph(url, token);
      if (data.ok) {
        const userData: User[] = await data.json();
        return userData;
      }
    } catch (error) {
      console.warn(
        'Error API "Services" | Function "getServicesByUserApi": ' + error);
    }
    return ;
  }
  export const getMeetingDocumentation = async (meetingId: string, token: string) => { 

    //console.log("Accediendo al método")

    const url = `${process.env.REACT_APP_API_URL}/Documentation/GetDocumentation/`+meetingId;
    try {
      const data: Response = await fetchMSGraph(url, token);
      if (data.ok) {
        const Data: any = await data.json();
        return Data;
      }
    } catch (error) {
      console.warn(
        'Error API "Services" | Function "getMeetingDocumentation": ' + error);
    }
    return ;
  }
export const fetchGetUserServices =
  (arrayUsers: User[], token: string): AppThunk =>
  async (dispatch) => {
    const userServices: UserWithServices[] = [];
    if (arrayUsers.length > 0) {
      try {
        await Promise.all(
          arrayUsers.map(async (user: User) => {
            let url = `${process.env.REACT_APP_API_URL}/Services/user/${user.code}`;
            const res: Response = await fetchMSGraph(url, token);
            if (res.ok) {
              const data: Service[] = await res.json();
              const arrayServices: Service[] = [];
              data.map((service) => {
                arrayServices.push(service);
              });
              //console.log("arrayServ", arrayServices);
              let r: UserWithServices = {
                active: user.active,
                mail: user.mail,
                name: user.name,
                services: arrayServices,
              };
              userServices.push(r);
            }
          })
        );
      } catch (error) {
        console.warn("Error Function fetchGetUserServices: ", error);
      }
    }
    //console.log("Services", userServices);
    await dispatch(GET_SERVICE_BY_USER(userServices));
  };

export const cleanServices = (): AppThunk => async (dispatch) => {
  let responseDispatch: servicesState = {
    users: [],
    serviceInfo: defaultServiceInfo,
    userServices: [],
    servicesUserDelete: { response: null },
    servicesUserPost: { response: null },
  };
  await dispatch(CLEAN_SERVICES(responseDispatch));
};

export const selectServiceUsers = (state: RootState) => state.services.users;
export const selectServicesUserPost = (state: RootState) =>
  state.services.servicesUserPost;
export const selectServiceUserDelete = (state: RootState) =>
  state.services.servicesUserDelete;
export const selectServicesByUser = (state: RootState) =>
  state.services.userServices;
export const selectServiceInfo = (state: RootState) =>
  state.services.serviceInfo;

export default servicesSlice.reducer;