import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "../../hooks/useForm";
import Card from "../Card/Card";
import Layout from "../Layout/LayoutContainer";
import { AddUserForm } from "./components/AddUserForm";
import ModalDeleteArea from "./components/ModalDeleteArea";
import ModalAddUserArea from "./components/ModalAddUserArea";
import {
  Button as ButtonSemantic,
  Dimmer,
  Form,
  Loader,
  Segment,
} from "semantic-ui-react";

import { AuthContext } from "../../context/AuthContext";
import {
  addAdminToArea,
  addUserToArea,
  addServiceManagerToArea,
  deleteArea,
  fetchAllUsers,
  fetchArea,
  fetchAreaAdmins,
  fetchAreaUsers,
  fetchAreaServiceManager,
  putArea,
} from "../../assets/api";
import { UserInfo } from "../../models/User";
import { Languages } from "../../types/service";
import { Button } from "../Button/Button";
import { LayoutContext } from "../../context/LayoutContext";
import { toast } from "react-toastify";
import moment from "moment";

type ResponseAPI = {
  type: string;
  time: string;
};
const defaultResponseAPI: ResponseAPI = {
  type: "",
  time: "",
};
interface Area {
  active: boolean;
  code: string;
  displayName: string;
  email: string;
  languages: Languages[];
  organizationCode?: string;
  phone?: string;
}

interface Office {
  active: boolean;
  name: string;
  mail: string;
  code: string;
}

const initializeArea: Area = {
  active: false,
  code: "",
  displayName: "",
  email: "",
  languages: [{ displayName: "", culture: "" }],
};

const initializeOffice: Office = {
  active: false,
  name: "",
  mail: "",
  code: "",
};

const defaultLang: Languages = { culture: "", displayName: "" };

const AreaDetail = () => {
  const [areaValues, setAreaValues, handleInputChangeArea, resetArea] =
    useForm(initializeArea);
  const [
    areaAdminValues,
    setAreaAdminValues,
    handleInputChangeAreaAdmin,
    resetAreaAdmin,
  ] = useForm(initializeOffice);
  const [
    areaOfficeValues,
    setAreaOfficeValues,
    handleInputChangeOfficeArea,
    resetAreaOffice,
  ] = useForm(initializeOffice);


  // .
  const [
    areaServiceManagerValues,
    setAreaServiceManagerValues,
    handleInputChangeServiceManagerValues,
    resetAreaServiceManagerValues,
  ] = useForm(initializeOffice);

  const [officeValues, setOfficeValues, handleInputChangeOffice, resetOffice] =
    useForm(initializeOffice);

  const { languages } = areaValues;

  const ca: Languages = languages.find(
    (lang: Languages) => lang.culture === "ca"
  );
  const es: Languages = languages.find(
    (lang: Languages) => lang.culture === "es"
  );
  const en: Languages = languages.find(
    (lang: Languages) => lang.culture === "en"
  );
  const oc: Languages = languages.find(
    (lang: Languages) => lang.culture === "oc"
  );

  const [formLangCA, setFormLangCA, handleInputChangeCA, resetCA] = useForm(ca);
  const [formLangES, setFormLangES, handleInputChangeES, resetES] = useForm(es);
  const [formLangEN, setFormLangEN, handleInputChangeEN, resetEN] = useForm(en);
  const [formLangOC, setFormLangOC, handleInputChangeOC, resetOC] = useForm(oc);
  
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const { setTitle, setBreadcrumb } = layoutContext;
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [loadingAdmin, setLoadingAdmin] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const [loadingServiceManager, setLoadingServiceManager] = useState<boolean>(false);

  const [responseUser, setresponseUser] =
    useState<ResponseAPI>(defaultResponseAPI);
  const [modal, setModal] = useState(false);
  const [modalServiceManager, setModalServiceManager] = useState(false);
  const [modalEmployee, setModalEmployee] = useState(false);
  const [openDeleteArea, setOpenDeleteArea] = useState<boolean>(false);

  const areaCodeFromPath = () => {
    const { pathname } = location;
    return pathname.split("/")[2];
  };

  const apiAreaUser = async () => {
    const arrayUser: UserInfo[] = [];
    try {
      const areaCode = areaCodeFromPath();
      const { backoffice } = await authContext.getTokenForScopes();
      const response: Response = await fetchAreaUsers(areaCode, backoffice);
      if (!response.ok) throw new Error(response.statusText);
      const data: UserInfo[] = await response.json();
      data.forEach((user: UserInfo) => {
        arrayUser.push(user);
      });
    } catch (error) {
      console.warn(error.message);
      toast(t("edit.area.message.charge.user_area_error"), { type: "error"});
    }
    setAreaOfficeValues(arrayUser);
  };

  /*
  const apiAreaServiceManager = async () => {
    const arrayServiceManager: UserInfo[] = [];
    try {
      const areaCode = areaCodeFromPath();
      const { backoffice } = await authContext.getTokenForScopes();
      const response: Response = await fetchAreaServiceManager(areaCode, backoffice);
      if (!response.ok) throw new Error(response.statusText);
      const data: UserInfo[] = await response.json();
      data.forEach((user: UserInfo) => {
        arrayServiceManager.push(user);
      });
    } catch (error) {
      console.warn(error.message);
      toast(t("edit.area.message.charge.user_area_error"), { type: "error"});
    }
    setAreaOfficeValues(arrayServiceManager);
  };*/

  const apiAreaAdmins = async () => {
    const arrayAdmin: UserInfo[] = [];
    try {
      const areaCode = areaCodeFromPath();
      const { backoffice } = await authContext.getTokenForScopes();
      const response: Response = await fetchAreaAdmins(areaCode, backoffice);
      if (!response.ok) throw new Error(response.statusText);
      const data: UserInfo[] = await response.json();
      data.forEach((user: UserInfo) => {
        arrayAdmin.push(user);
      });
    } catch (error) {
      console.warn(error.message);
      toast(t("edit.area.message.charge.admin_area_error"), { type: "error"});
    }
    await setAreaAdminValues(arrayAdmin);
  };

  const historyHandleClick = () => {
    history.replace("/organitzation");
  };

  const handleOpenDeleteArea = (value: boolean) => {
    setOpenDeleteArea(value);
  };

  const handleOpenAddAdminArea = (value: boolean) => {
    setModal(value);
  };

  const handleOpenAddServiceManager = (value: boolean) => {
    setModalServiceManager(value);
  };

  const handleOpenAddUserArea = (value: boolean) => {
    setModalEmployee(value);
  };

  const handleDeleteArea = async (areaCode: string) => {
    const { backoffice } = await authContext.getTokenForScopes();
    try {
      const response = await deleteArea(areaCode, backoffice);
      if (response.ok) {
        toast(t("edit.area.delete.success"), { type: "success"});
        historyHandleClick();
      } else {
        throw new Error(t("edit.area.delete.error"));
      }
    } catch (e) {
      toast((e.message), { type: "error"});
    }
  };

  const arrayButtons = [
    {
      icon: "remove",
      message: "edit.area.delete.btn_title",
      onClick: () => handleOpenDeleteArea(true),
    },
  ];

  useEffect(() => {}, []);

  useEffect(() => {
    const title = t("edit.area.title");

    setTitle(title);
    setBreadcrumb([
      {
        name: t("area.title"),
        link: "/organitzation",
      },
      {
        name: t("edit.area.title"),
      },
    ]);

    init();
  }, [t, setTitle, setBreadcrumb]);

  useEffect(() => {
    (async () => {
      switch (responseUser.type) {
        case "add":
          await apiAreaAdmins();
          await apiAreaUser();
          //await apiAreaServiceManager();
          break;
        case "delete":
          await apiAreaAdmins();
          await apiAreaUser();
          //await apiAreaServiceManager();
          break;
      }
      setLoadingUser(false);
    })();
  }, [responseUser]);

  useEffect(() => {
    const setForms = async () => {
      const ca = languages.find((lang: Languages) => lang.culture === "ca")
      const es = languages.find((lang: Languages) => lang.culture === "es")
      const en = languages.find((lang: Languages) => lang.culture === "en")
      const oc = languages.find((lang: Languages) => lang.culture === "oc")

      await setFormLangCA(ca !== undefined ? ca : {culture: 'ca', displayName: ''});
      await setFormLangES(es !== undefined ? es : {culture: 'es', displayName: ''});
      await setFormLangEN(en !== undefined ? en : {culture: 'en', displayName: ''});
      await setFormLangOC(oc !== undefined ? oc : {culture: 'oc', displayName: ''});
    };

    setForms();
  }, [loading]);

  const init = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    const areaCode = areaCodeFromPath();
    try {
      await fetchArea(areaCode, backoffice)
        .then((response) => {
          if (!response.ok)
            throw new Error(t("edit.area.message.charge_area_error"));

          return response.json();
        })
        .then((response) => {
          setAreaValues(response);
        });
      await fetchAllUsers(backoffice)
        .then(async (response: Response) => {
          if (response.ok) {
            const data = await response.json();
          
            return data;
          } else {
            throw new Error(t("edit.area.message.change.user_error"));
          }
        })
        .then((response) => {
          setOfficeValues(response);
        });
    } catch (er) {
      toast((er.message), { type: "error"});
    }
    await apiAreaAdmins();
    await apiAreaUser();
    //await apiAreaServiceManager();
    setLoading(false);
  };

  const handleSubmitAddUser = async (users: UserInfo[]) => {
    await setLoadingUser(true);
    const { backoffice } = await authContext.getTokenForScopes();
    const areaCode = areaCodeFromPath();
    users.forEach((user) => {
      try {
        addAdminToArea(areaCode, user.code, backoffice)
          .then((response) => {
            if (!response.ok)
              throw new Error(t("edit.area.message.add_admin.error"));
            return response.json();
          })
          .then(async (response) => {
            toast(t("edit.area.message.add_admin.success"), { type: "success"});
            await fetchAreaAdmins(areaCode, backoffice)
              .then((response) => {
                if (!response.ok)
                  throw new Error(
                    t("edit.area.message.charge.admin_area_error")
                  );

                return response.json();
              })
              .then((response) => {
                setAreaAdminValues(response);
                setresponseUser({
                  type: "add",
                  time: moment().format("MM/DD/YYYY, h:mm:ss a"),
                });
                setModal(false);
              });
          });
      } catch (er) {
        setModal(false);
        toast((er.message), { type: "error"});
      }
    });
  };

  const handleSubmitAddServiceManager = async (users: UserInfo[]) => {
    await setLoadingServiceManager(true);
    const { backoffice } = await authContext.getTokenForScopes();
    const areaCode = areaCodeFromPath();
    users.forEach((user) => {
      try {
        addServiceManagerToArea(areaCode, user.code, backoffice)
          .then((response) => {
            if (!response.ok)
              throw new Error(t("edit.area.message.add_admin.error"));
            return response.json();
          })
          .then(async (response) => {
            toast(t("edit.area.message.add_admin.success"), { type: "success"});
            await fetchAreaServiceManager(areaCode, backoffice)
              .then((response) => {
                if (!response.ok)
                  throw new Error(
                    t("edit.area.message.charge.admin_area_error")
                  );

                return response.json();
              })
              .then((response) => {
                setAreaServiceManagerValues(response);
                setresponseUser({
                  type: "add",
                  time: moment().format("MM/DD/YYYY, h:mm:ss a"),
                });
                setModalServiceManager(false);
              });
          });
      } catch (er) {
        setModal(false);
        toast((er.message), { type: "error"});
      }
    });
  };

  const handleSubmitAddEmployee = async (uservalues: UserInfo[]) => {
    await setLoadingUser(true);
    const { backoffice } = await authContext.getTokenForScopes();
    const areaCode = areaCodeFromPath();
    uservalues.forEach((uservalue) => {
      const user = officeValues.find(
        (employee: any) => employee.mail === uservalue.mail
      );

      try {
        addUserToArea(areaCode, user.code, backoffice)
          .then(async (response) => {
            if (!response.ok)
              throw new Error(t("edit.area.message.add_user.error"));

            return await response.json();
          })
          .then(async (response) => {
            toast(t("edit.area.message.add_user.success"), { type: "success"});

            await fetchAreaUsers(areaCode, backoffice)
              .then((response) => {
                if (!response.ok)
                  throw new Error(
                    t("edit.area.message.charge.user_area_error")
                  );

                return response.json();
              })
              .then((response) => {
                setAreaOfficeValues(response);
                setModalEmployee(false);
                setresponseUser({
                  type: "add",
                  time: moment().format("MM/DD/YYYY, h:mm:ss a"),
                });
              });
          });
      } catch (er) {
        toast((er.message), { type: "error"});
      }
    });
    handleOpenAddUserArea(false);
  };

  const handleUpdateArea = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    const formLanguages: Languages[] = [
      { ...formLangCA },
      { ...formLangES },
      { ...formLangEN },
      { ...formLangOC },
    ];

    setAreaValues((areas: Area) => {
      areas.languages = formLanguages;
      areas.displayName = formLanguages[0].displayName;
      return areas;
    });

    try {
      putArea(areaValues, backoffice)
        .then((response) => {
          if (!response.ok)
            throw new Error(t("edit.area.message.area.update.error"));

          return response.json();
        })
        .then((response) => {
          toast(t("edit.area.message.area.update.success"), { type: "success"});
          historyHandleClick();
        });
    } catch (er) {
      toast((er.message), { type: "error"});
    }
  };

  return (
    <>
      <Layout.Main>
        {loading ? (
          <Dimmer active inverted size={"massive"}>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : (
          <>
            <Card>
              <Card.Body>
                <Form style={{ boxShadow: "none" }} className="border-0">
                  <Form.Field>
                    <label> {t("edit.area.name")} (ca)</label>
                    <input
                      name="displayName"
                      value={formLangCA.displayName}
                      onChange={handleInputChangeCA}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label> {t("edit.area.name")} (es)</label>
                    <input
                      name="displayName"
                      value={formLangES.displayName}
                      onChange={handleInputChangeES}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label> {t("edit.area.name")} (en)</label>
                    <input
                      name="displayName"
                      value={formLangEN.displayName}
                      onChange={handleInputChangeEN}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label> {t("edit.area.name")} (oc)</label>
                    <input
                      name="displayName"
                      value={formLangOC.displayName}
                      onChange={handleInputChangeOC}
                    />
                  </Form.Field>

                  {loadingUser ? (
                    <Segment padded={"very"}>
                      <Dimmer active inverted size={"massive"}>
                        <Loader size="large">{t("loading.defect")}</Loader>
                      </Dimmer>
                    </Segment>
                  ) : (
                    <AddUserForm
                      type="admin"
                      area={areaCodeFromPath()}
                      title={t("edit.area.admins")}
                      className="my-4"
                      users={areaAdminValues}
                      onClick={() => setModal(true)}
                      handleResponseAPI={setresponseUser}
                      handleLoader={setLoadingUser}
                    />
                  )}



                  {loadingUser ? (
                    <Segment padded={"very"}>
                      <Dimmer active inverted size={"massive"}>
                        <Loader size="large">{t("loading.defect")}</Loader>
                      </Dimmer>
                    </Segment>
                  ) : (
                    <AddUserForm
                      type="office"
                      area={areaCodeFromPath()}
                      title={t("edit.area.employees")}
                      className="mb-4"
                      users={areaOfficeValues}
                      onClick={() => setModalEmployee(true)}
                      handleResponseAPI={setresponseUser}
                      handleLoader={setLoadingUser}
                    />
                  )}

                  <ButtonSemantic
                    onClick={() => history.goBack()}
                    negative
                    content={t("go_back")}
                    icon="arrow left"
                  />

                  <ButtonSemantic
                    positive
                    icon="edit"
                    content={t("edit.area.save")}
                    className="mt-3"
                    onClick={handleUpdateArea}
                  />
                </Form>
              </Card.Body>
            </Card>
          </>
        )}
      </Layout.Main>
      <Layout.Article>
        {arrayButtons.map((button) => (
          <Button
            message={button.message}
            icon={button.icon}
            onClick={button.onClick}
          />
        ))}
      </Layout.Article>
      <ModalAddUserArea
        open={modal}
        handleOpen={handleOpenAddAdminArea}
        handleSubmit={handleSubmitAddUser}
        type="admin"
      />

      <ModalAddUserArea
        open={modalServiceManager}
        handleOpen={handleOpenAddServiceManager}
        handleSubmit={handleSubmitAddServiceManager}
        type="serviceManager"
      />

      <ModalAddUserArea
        open={modalEmployee}
        handleOpen={handleOpenAddUserArea}
        handleSubmit={handleSubmitAddEmployee}
        type="user"
      />
      <ModalDeleteArea
        open={openDeleteArea}
        handleOpen={handleOpenDeleteArea}
        handleDelete={handleDeleteArea}
      />
    </>
  );
};

export default AreaDetail;
