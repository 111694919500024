import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Modal, TextArea } from "semantic-ui-react";
import {
  evaluate,
  validateEmail,
  validateNumber,
  validatePhone,
  validateText,
} from "./validations";
interface Props {
  title: string;
  value: string;
  onChange: (x: any, y?: any) => void;
  textError?: string;
  required?: boolean;
  name: string;
  format?:any // "phone" | "email" | "text" | "number" | "none" | "boolean" | "comment"| "any";
  label?: string;
  isError?: boolean;
  autoComplete?: string;
}
const Field = (props: Props) => {
  const [isError, setIsError] = useState(false);
  const renderInput = (props: Props) => {
    const value: any = props.value;
    if (props.format === "boolean")
      return <Checkbox {...props} toggle checked={value} />;
    else if (props.format === "comment") {
      return (
        <TextArea
          {...props}
          label={props.label}
          name={props.name}
          type={props.format}
          value={props.value}
          onChange={(env, data: any) => {
            props.onChange(env, data);
          }}
        />
      )
    }
    else
      return (
        <Input
          {...props}
          label={props.label}
          name={props.name}
          type={props.format === "number" ? "number" : "text"}
          value={props.value}
          onChange={(env, data: any) => {
            props.onChange(env, data);
          }}
        />
      );
  };
  return (
    <div onBlur={() => evaluate(props, setIsError)}>
      <Form.Field required={props.required}>
        <label>{props.title}</label>
        {renderInput(props)}
        {isError && props.textError && (
          <span className="text-danger">{props.textError}</span>
        )}
      </Form.Field>
    </div>
  );
};

export default Field;
