import { mapAsyncFlatten, toArray } from './../util/array';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../app/store";
import { Service } from "../models/Service";
import { User } from "../models/User";
import { Area } from "../models/Area";
import { fetchMSGraph } from "../util/auth";
import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";
import { EnumAPI } from "../enum/EnumAPI";
import { toast } from "react-toastify";
import { ServiceInfo } from "../models/ServiceInfo";
import { join } from 'path';

const URL_AREA = `${process.env.REACT_APP_API_URL}/${EnumAPI.Areas}`;

interface AreaState {
  areas: Area[];
  services: Service[];
  users: User[];
  myAreas: Area[];
}

const initialState: AreaState = {
  areas: [],
  services: [],
  users: [],
  myAreas: [],
};

export const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    GET_AREA: (state, action: PayloadAction<Area[]>) => {
      state.areas = action.payload;
    },
    GET_AREA_SERVICE: (state, action: PayloadAction<Service[]>) => {
      state.services = action.payload;
    },
    GET_AREA_USER: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
    GET_MY_AREAS: (state, action: PayloadAction<Area[]>) => {
      state.myAreas = action.payload;
    },
  },
});

export const { GET_AREA, GET_AREA_SERVICE, GET_AREA_USER, GET_MY_AREAS } =
  areaSlice.actions;

export const fetchGetAreasApi =
  (token: string): AppThunk =>
    async (dispatch) => {
      const url = `${URL_AREA}`;
      let data: Area[] = [];
      let areas: Area[] = [];
      try {
        const response: Response = await fetchMSGraph(url, token);
        if (response.ok) {
          data = await response.json();
          data.map((area: Area) => areas.push(area));
        } else {
          data = await response.json();
        
        }
      } catch (error) {
        console.warn("Error Function fetchGetAreaApi: ", error);
      }
      dispatch(GET_AREA(areas));
    };

export const fetchGetAreasServicesApi =
  (area: string | string[], token: string): AppThunk =>
    async (dispatch) => {
      const areaArray: string[] = toArray(area);
      const arrayServices = await mapAsyncFlatten(areaArray, (area) => getAreasServicesApi(area, token))
      await dispatch(GET_AREA_SERVICE(arrayServices));
    };

export const getAreasUsers = async (areacode: string, token: string) => {
  const url = `${URL_AREA}/${areacode}/users`;
  const arrayUser: User[] = [];
  try {
    const response = await fetchMSGraph(url, token);
    if (response.ok) {
      const data: User[] = await response.json();
      data.forEach((user: User) => {
        arrayUser.push(user);
      });
    }
  } catch (error) {
    console.warn(
      'Error "AREAS" - Function "fetchGetAreasUsersApi" : ',
      error
    );
  }
  const a = arrayUser.slice();
  return a;
}
export const getAreasServicesApi = async (area: string, token: string) => {

  const url = `${URL_AREA}/${area}/services`;
  const arrayServices: Service[] = [];
  try {
    const response = await fetchMSGraph(url, token);
    if (response.ok) {
      const responseServices: Service[] = await response.json();
      responseServices.forEach((service) => {
        arrayServices.push(service);
      });
    }
  } catch (error) {
    console.warn("Error Function fetchServicesApi: " + error);
  }
  return arrayServices;
}


export const fetchGetAreasUsersApi =
  (areacode: string, token: string): AppThunk =>
    async (dispatch) => {
      const arrayUser: User[] = [];
      const url = `${URL_AREA}/${areacode}/users`;
      try {
        const response = await fetchMSGraph(url, token);
        if (response.ok) {
          const data: User[] = await response.json();
          data.forEach((user: User) => {
            arrayUser.push(user);
          });
        }
      } catch (error) {
        console.warn(
          'Error "AREAS" - Function "fetchGetAreasUsersApi" : ',
          error
        );
      }
      const a = arrayUser.slice();
      await dispatch(GET_AREA_USER(a));
    };

export const fetchAreaPublished =
  (
    areaCode: string,
    token: string,
    successM: string,
    errorM: string
  ): AppThunk =>
    async (dispatch) => {
      const url = `${URL_AREA}/${areaCode}/services/publish`;
      try {
        const response: Response = await fetchMSGraph(
          url,
          token,
          EnumHTTPMethod.POST
        );
        if (!response.ok) throw new Error("Error fecthMyAreas");
        toast((`${successM} ${areaCode}`), { type: "success"});
      } catch (error) {
        console.warn(error);
        toast((`${errorM} ${areaCode}`), { type: "error"});
      }
    };

export const fecthMyAreas =
  (token: string): AppThunk =>
    async (dispatch) => {
      const url = `${URL_AREA}/admin/myareas`;
      const arrayAreas: Area[] = [];
      try {
        const response: Response = await fetchMSGraph(url, token);
        if (!response.ok) throw new Error("Error fecthMyAreas");
        const data: Area[] = await response.json();
        data.forEach((a: Area) => {
          arrayAreas.push(a);
        });
      } catch (error) {
        console.warn(error);
      }
      dispatch(GET_MY_AREAS(arrayAreas));
    };

export const cleanAreasApi = (): AppThunk => async (dispatch) => {
  const arrayServices: Service[] = [];
  await dispatch(GET_AREA_SERVICE(arrayServices));
};

export const selectAreas = (state: RootState) => state.areas.areas;
export const selectServices = (state: RootState) => state.areas.services;
export const selectAreaUsers = (state: RootState) => state.areas.users;
export const selectMyAreas = (state: RootState) => state.areas.myAreas;

export default areaSlice.reducer;

export const getServicesCodes = async (token: string) => {

  const endpoint = `${process.env.REACT_APP_API_URL}/Services`;
  try {
    const response = await fetchMSGraph(endpoint, token);
    if (response.ok) {
      const data: ServiceInfo[] = await response.json();
      return data.map(_ => _.code);
    }
  } catch (error) {
    console.warn(
      'Error "AREAS" - Function "getServicesCodes" : ',
      error
    );
  }
  return []
}

export const getServices = async (token: string) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services`;
  const response = await fetchMSGraph(endpoint, token).then(info => info.json());
  return response;
}


export const getServicesUser = async (userCode: string, token: string) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/user/${userCode}`;
  const response = await fetchMSGraph(endpoint, token);
  const data = await response.json()
  const adaptedData = data.map(({ active, displayName, code }: any) => ({ active, displayName, code }));
  return adaptedData;
}

export const fetchServices = (token: string): AppThunk => async (dispatch) => {
  const response = await getServices(token);
  dispatch(GET_AREA_SERVICE(response));
}

export const fetchServicesByUser = (userCode: string, token: string): AppThunk => async (dispatch) => {
  const response = await getServicesUser(userCode, token);
  dispatch(GET_AREA_SERVICE(response));
}

export async function updateInformersNameArea(
  code: string,
  body: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${code}`;
  const bodyStringify = JSON.stringify(body);
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.PUT, bodyStringify)
  return response;
}
