import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "semantic-ui-react";
interface Props {
  value: any;
  type: string;
}
const CardInformation = (props: Props) => {
  const Text = ({ children }: any) => <h3>{children}</h3>;

  const { t } = useTranslation();
  const format = typeof props.value;
  console.log(
    "Props CardInformation",
    props.type,
    props.value,
    format,
    props.value && !Array.isArray(props.value) && true
  );

  const renderInformation = ({ type, value, format }: any) => {
 
    if (format === "object") {
      return (
        <>
          {props.value && props.value.length > 0 &&(
            <ul>
              {props.value.map((_: any) => (
                <li>
                  <Text>{_.description}</Text>
                </li>
              ))}
            </ul>
          )}
          {((!props.value || props.value.length === 0) && (type !== 'subjects1' && type !== 'subjects2' && type !== 'subjects3')) && (
            <Text>{t(`home.card.${props.type}_empty`)}</Text>
          )}
        </>
      );
    } else if (format === "string") {
      return (
        <>
          {props.value && <Text>{props.value}</Text>}
          {!props.value && <Text>{t(`home.card.${props.type}_empty`)}</Text>}
        </>
      );
    } else if (format === "boolean" && type === "isOtpAvailable") {
      return (
        <>
          {!props.value && <Text>{t(`home.card.no_verification_otp`)}</Text>}
          {props.value && <Text>{t(`home.card.verification_otp`)}</Text>}
        </>
      );
    } else {
      if(type !== 'subjects1' && type !== 'subjects2' && type !== 'subjects3') {
        return <Text>{t(`${props.type}_empty`)}</Text>;
      }
    }
  };
  //((props.type === "subjects1" || props.type === "subjects2" || props.type === "subjects3") && props.value.length > 0) ||  (props.type !== "subjects1" && props.type !== "subjects2" && props.type !== "subjects3") && (
  return (
    (props.value !== undefined )&& (
      (((props.type === "subjects1" || props.type === "subjects2" || props.type === "subjects3") && (props.value && props.value.length > 0)) ||  (props.type !== "subjects1" && props.type !== "subjects2" && props.type !== "subjects3")) && (
    <li className="margin-top-card-small">
      <div
        style={{
          display:
            format !== "object" || !props.value || props.value.length === 0
              ? "flex"
              : "block",
        }}
      >
        <Text>{t(`home.card.${props.type}`)}</Text>
        <div className="ml-2">
          {renderInformation({ value: props.value, type: props.type, format })}
        </div>
      </div>
    </li> ) )
  );
};

export default CardInformation;
